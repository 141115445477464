import Vue from 'vue'
import OverviewChartTooltip from '~/components/molecules/OverviewChartTooltip.vue'
import { hoursToTimeString } from '~/utils/formatTime'

const TooltipClass = Vue.extend(OverviewChartTooltip)
interface HighchartColors {
  primary: Array<string>
  secondary: Array<string>
}

declare module 'vue/types/vue' {
  interface Vue {
    $createHighChartOverviewOptions(
      data: any,
      brochureData: any,
      color: String,
      startAt: String,
      endAt: String,
      name: String
    ): Highcharts.Options
    $createHighChartAdminReadsOptions(data: any): Highcharts.Options
    $createHighChartTaggingStatisticsOptions(data: any): Highcharts.Options
    $createHighChartPageOptions(
      data: any,
      color: String,
      name: String
    ): Highcharts.Options
    $highchartColors: HighchartColors
    $createHighChartUserSessionsOptions(
      data: any,
      color?: String
    ): Highcharts.Options
  }
}
Vue.prototype.$createHighChartAdminReadsOptions = (data: any) => {
  return {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#ffffff',
      className: 'admin-reads-chart',
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
      },
    },
    xAxis: {
      type: 'category',
    },
    series: data,
    yAxis: [
      {
        title: {
          text: 'Reads',
          style: {
            color: '#000000',
          },
        },
      },
    ],
  }
}
Vue.prototype.$createHighChartTaggingStatisticsOptions = (data: any) => {
  return {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#ffffff',
      className: 'admin-reads-chart',
      type: 'column',
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
      },
    },
    xAxis: {
      type: 'category',
    },
    series: data,
    yAxis: [
      {
        title: {
          text: 'Total tagged',
          style: {
            color: '#000000',
          },
        },
      },
    ],
  }
}
Vue.prototype.$createHighChartOverviewOptions = (
  sumData: any,
  brochureData: any,
  color: String,
  startAt: String,
  endAt: String,
  name: String
) => {
  return {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#ffffff',
      className: 'my-chart',
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter(): String | undefined {
          // @ts-ignore:next-line
          if (this.isFirst === true) {
            return startAt
            // @ts-ignore:next-line
          } else if (this.isLast === true) {
            return endAt
          }
        },
      },
    },
    yAxis: [
      {
        title: {
          text: undefined,
          style: {
            color: '#000000',
          },
        },
      },
    ],
    series: [
      {
        name,
        data: sumData,
        color,
      },
    ],
    tooltip: {
      useHTML: true,
      borderWidth: 0,
      shadow: false,
      backgroundColor: undefined,
      formatter(): String {
        // @ts-ignore:next-line
        const xVal = this.x
        const toolTipInstance = new TooltipClass({
          propsData: {
            brochureData,
            sumData,
            x: xVal,
            color,
            name,
          },
        })
        toolTipInstance.$mount()
        const html = toolTipInstance.$el.outerHTML
        return html
      },
    },
  }
}

Vue.prototype.$createHighChartPageOptions = (
  data: any,
  color: String,
  name: String
) => {
  return {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      type: 'column',
      backgroundColor: '#ffffff',
      className: 'my-chart',
      height: 500,
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
      },
    },
    yAxis: [
      {
        title: {
          text: undefined,
          style: {
            color: '#000000',
          },
        },
        overflow: 'justify',
      },
    ],
    series: [
      {
        name,
        data,
        color,
        borderRadius: 4,
      },
    ],
  }
}

Vue.prototype.$highchartColors = {
  primary: [
    '#BF5AF2',
    '#5E5CE6',
    '#40C8E0',
    '#0A84FF',
    '#30D158',
    '#FFD60A',
    '#FF453A',
    '#FF993A',
  ],
  secondary: [
    '#EBCAFB',
    '#CCCBF7',
    '#B1D8FF',
    '#C2EDF5',
    '#BDF0CA',
    '#FFF2B1',
    '#FFC4C0',
    '#FFCC9C',
  ],
}

Vue.prototype.$createHighChartUserSessionsOptions = (
  data: any,
  color: String = '#f08030'
) => {
  return {
    title: {
      text: undefined,
    },
    tooltip: {
      formatter(): string {
        //@ts-ignore:next-line
        return hoursToTimeString(this.point.y)
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#ffffff',
      className: 'my-chart',
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
      },
    },
    xAxis: {
      type: 'category',
    },
    yAxis: [
      {
        title: {
          text: 'Total session time (in hours)',
          style: {
            color: '#000000',
          },
        },
      },
    ],
    series: [
      {
        name: 'Session Time',
        data,
        color,
      },
    ],
  }
}
