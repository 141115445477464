import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.timers.js";
import { useMeQuery, useUpsertSessionMutation } from '~~/graphql/composables';
export default function useTimeTracking() {
  // Get the user from the meQuery
  var _useMeQuery = useMeQuery(),
    result = _useMeQuery.result;
  var _useUpsertSessionMuta = useUpsertSessionMutation(),
    mutate = _useUpsertSessionMuta.mutate;
  var interval = ref(null);
  watch(result, function (user) {
    var _user$me, _user$me2;
    // Only track for users with admin and tagger roles
    if (!user || !((_user$me = user.me) !== null && _user$me !== void 0 && _user$me.roles.includes('admin')) && !((_user$me2 = user.me) !== null && _user$me2 !== void 0 && _user$me2.roles.includes('tagger'))) {
      stopInterval(interval);
      return;
    }

    // If the user is a tagger or admin, start the interval
    interval.value = setInterval(function () {
      if (document.visibilityState === 'visible') {
        mutate();
      }
    }, 1000 * 30); // 30 seconds
  }, {
    immediate: true,
    deep: true
  });
  onUnmounted(function () {
    stopInterval(interval);
  });
}
function stopInterval(interval) {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}