/**
 * Converts a number of hours to a time string in the format HH:MM.
 * @param hours - The number of hours to convert.
 * @returns A string representing the time in HH:MM format.
 */
export function hoursToTimeString(hours: number) {
  const totalMinutes = Math.floor(hours * 60)
  const hoursPart = String(Math.floor(totalMinutes / 60)).padStart(2, '0')
  const minutesPart = String(totalMinutes % 60).padStart(2, '0')
  return `${hoursPart}:${minutesPart}`
}
