import { render, staticRenderFns } from "./MenuSideBar.vue?vue&type=template&id=7d3b2a4f&scoped=true"
import script from "./MenuSideBar.vue?vue&type=script&lang=ts"
export * from "./MenuSideBar.vue?vue&type=script&lang=ts"
import style0 from "./MenuSideBar.vue?vue&type=style&index=0&id=7d3b2a4f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3b2a4f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuSideBarSection: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/molecules/MenuSideBarSection.vue').default,SideBarItem: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/molecules/SideBarItem.vue').default,SideBar: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/molecules/SideBar.vue').default})
