import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7013cf5e"
import script from "./default.vue?vue&type=script&setup=true&lang=ts"
export * from "./default.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=7013cf5e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuSideBar: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/organisms/MenuSideBar.vue').default,SnackbarContainer: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/molecules/SnackbarContainer.vue').default})
